import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";

const LoadingProgressBar = () => {
    const [progressNow, setProgressNow] = useState(0)
    const updateProgressNowHandler = setInterval(() => {
        if (progressNow >= 100) {
            setProgressNow(100)
            clearInterval(updateProgressNowHandler)
        }
        setProgressNow(s => s + 1)
    }, 700)
    return (
        <ProgressBar animated now={progressNow}/>
    )
}
export default LoadingProgressBar;