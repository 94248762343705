import React from 'react';
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "./firebase-config";

const Paid = ({ player }) => {
    const playersRef = collection(db, 'players')

    const editPaid = (player) => {
        let paidStr = prompt("How much?", player.paid);
        const newPlayer = {
            ...player,
            paid: parseInt(paidStr),
        }
        setDoc(doc(playersRef, player.id), newPlayer);
    }
    const owed = player.count * 10 - player.paid
    const className = (owed > 0) ? 'badge bg-danger' : 'badge bg-success'
    const moneyDisplay = (owed === 0) ? 'paid' : '$' + owed
    return (
        <span className={className} onDoubleClick={() => editPaid(player)}>{moneyDisplay}</span>
    )
}

export default Paid;
