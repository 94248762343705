import React, { useEffect, useState } from 'react';
import { sum, orderBy } from 'lodash'
import Paid from "../Paid";
import { ProgressBar } from "react-bootstrap";
import LoadingProgressBar from "../LoadingProgressBar";

const Players = ({ players, results }) => {
    const [displayPlayers, setDisplayPlayers] = useState([])
    const [totalSquares, setTotalSquares] = useState(0)
    const [totalPaid, setTotalPaid] = useState(0)
    const [totalUnPaid, setTotalUnPaid] = useState(0)
    useEffect(() => {
        if (players.length && results?.rounds) {
            const p = players.map(player => {
                const found = results.gameDisplayList.filter(game => {
                    return game.winner?.user?.name === player.name
                }).map(f => f.amount)
                player.winnings = sum(found)
                return player;
            }).filter(player => {
                return player.count > 0
            })
            let paid = sum(p.map(player => player.paid));
            setTotalPaid(paid)
            let squares = sum(p.map(player => player.count));
            setTotalSquares(squares)
            setTotalUnPaid(squares * 10 - paid)
            setDisplayPlayers(orderBy(p, ['winnings'], ['desc']));
        }
    }, [players, results])
    if (!players.length || !results?.rounds) {
        return (<LoadingProgressBar/>)
    }
    return (
        <table className="table table-sm table-responsive">
            <thead>
            <tr>
                <th>Player</th>
                <th>Picks</th>
                <th>Winnings</th>
                <th>Owe</th>
            </tr>
            </thead>
            <tbody>
            {displayPlayers.map(player => (
                <tr key={player.id}>
                    <td>{player.name}</td>
                    <td>{player.count}</td>
                    <td><span className="badge bg-success">${player.winnings || 0}</span></td>
                    <td><Paid player={player}/></td>
                </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
                <td></td>
                <td>{totalSquares}</td>
                <td></td>
                <td>
                    <span className="badge bg-success">${totalPaid}</span>
                    <span className="badge bg-danger">${totalUnPaid}</span>
                </td>
            </tr>
            </tfoot>
        </table>
    );
}

export default Players;
