import React, { useState } from 'react';
import { LinkContainer } from "react-router-bootstrap";

const LeftNav = ({ toggleNav }) => {
    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark" id="sidenav-main">
            <div className="sidenav-header">
                <i className="p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none material-icons" aria-hidden="true" id="iconSidenav">sports_basketball</i>
                <a className="navbar-brand m-0">
                    <span className="ms-1 font-weight-bold text-white">March Madness Scoreboard</span>
                </a>
            </div>
            <hr className="horizontal light mt-0 mb-2"/>
            <div className="collapse navbar-collapse  w-auto max-height-vh-100" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <LinkContainer to="/" onClick={toggleNav}>
                            <a className="nav-link text-white">
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">grid_on</i>
                                </div>

                                <span className="nav-link-text ms-1">Picks</span>
                            </a>
                        </LinkContainer>
                    </li>
                    <li className="nav-item">
                        <LinkContainer to="/players" onClick={toggleNav}>
                            <a className="nav-link text-white ">
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">people_outline</i>
                                </div>
                                <span className="nav-link-text ms-1">Players</span>
                            </a>
                        </LinkContainer>
                    </li>
                    <li className="nav-item">
                        <LinkContainer to="/results" onClick={toggleNav}>
                            <a className="nav-link text-white ">
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">view_list</i>
                                </div>
                                <span className="nav-link-text ms-1">Results</span>
                            </a>
                        </LinkContainer>
                    </li>
                    <li className="nav-item">
                        <LinkContainer to="/about" onClick={toggleNav}>
                            <a className="nav-link text-white ">
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">info</i>
                                </div>
                                <span className="nav-link-text ms-1">About</span>
                            </a>
                        </LinkContainer>
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default LeftNav;
