import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBijzbnqSAkYy1i_hAv5osl3ENkXXN2LC4",
    authDomain: "mm-scoreboard-154316.firebaseapp.com",
    projectId: "mm-scoreboard-154316",
    storageBucket: "mm-scoreboard-154316.appspot.com",
    messagingSenderId: "1019052093532",
    appId: "1:1019052093532:web:a79cf13c8dee54c6ecb699",
    measurementId: "G-T4KFHDCEY3"
};
const firebaseConfig_1 = {
    apiKey: "AIzaSyDC9ywAA-WmqwRMUDnpFTO7I4Pwdfid2Kg",
    authDomain: "mm-scoreboard-2.firebaseapp.com",
    projectId: "mm-scoreboard-2",
    storageBucket: "mm-scoreboard-2.appspot.com",
    messagingSenderId: "623518367370",
    appId: "1:623518367370:web:993987e1b81c01a97af773",
    measurementId: "G-3VJVCXT35J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();



