import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Picks from "./pages/Picks";
import Results from "./pages/Results";
import About from "./pages/About";
import Players from "./pages/Players";
import LeftNav from "./LeftNav";
import { useEffect, useState } from "react";
import { signInWithPopup, signOut } from "firebase/auth";
import { auth, db, provider } from "./firebase-config";
import { collection, doc, getDoc, setDoc, query, getDocs, where, onSnapshot, addDoc } from "firebase/firestore";
import TopNav from "./TopNav";
import getScores from "./service/resultsService";

const SHOW_CLASSES = 'g-sidenav-show bg-gray-200 g-sidenav-pinned dark-version'
const App = () => {
    const [user, setUser] = useState({})
    const [picks, setPicks] = useState([])
    const [containerClasses, setContainerClasses] = useState()
    const [players, setPlayers] = useState([])
    const [winnerScores, setWinnerScores] = useState([])
    const [loserScores, setLoserScores] = useState([])
    const playersRef = collection(db, 'players')
    const logout = () => {
        setUser({});
        localStorage.setItem('isAuth', false);
    }
    const updateUser = async (user, numPicks) => {
        const newUser = {
            email: user.email,
            name: user.name,
            paid: user.paid,
            count: numPicks
        }
        await setDoc(doc(playersRef, user.id), newUser);

    }
    const toggleNav = () => {
        const newClasses = containerClasses === SHOW_CLASSES ? '' : SHOW_CLASSES
        setContainerClasses(newClasses)
    }
    useEffect(() => {
        getPicks();
        getPlayers();
        // getScoresFromDb();
    }, [])
    const getPicks = async () => {
        const q = query(collection(db, "picks"), where("year", "==", 2024));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const us = [];
            querySnapshot.forEach((doc) => {
                us.push({ ...doc.data(), id: doc.id });
            });
            // console.log('picks:', us);
            setPicks(us);
        });
    }
    const getScoresFromDb = async () => {
        const docRef = doc(db, "scores", "2024");
        const docSnap = await getDoc(docRef);
        setWinnerScores(docSnap.data().winner)
        setLoserScores(docSnap.data().loser)
        return docSnap.data();
    }
    const getPlayers = async () => {
        const q = query(collection(db, "players"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const us = [];
            querySnapshot.forEach((doc) => {
                us.push({ ...doc.data(), id: doc.id });
            });
            setPlayers(us);
        });
    }
    const [results, setResults] = useState();
    useEffect(() => {
        if (picks.length) {
            retrieveResults().then(scrollIntoView)
        }
    }, [picks])
    const retrieveResults = async () => {
        const {loser, winner} = await getScoresFromDb();
        const res = await getScores(picks, loser, winner);
        setResults(res);
    }
    const createUser = async (user) => {
        await addDoc(collection(db, 'players'), user);
    }
    const scrollIntoView = () => {
        const lastFinal = document.getElementsByClassName('final');
        if (lastFinal.length) {
            lastFinal[lastFinal.length - 1].scrollIntoView();
        }
    }
    return (
        <Router>
            <div className={containerClasses}>
                <LeftNav toggleNav={toggleNav}/>
                <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                    <TopNav toggleNav={toggleNav} />
                    <div className="container-fluid">
                        <Routes>
                            <Route path='/' element={<Picks winnerScores={winnerScores} loserScores={loserScores} user={user} logout={logout} setUser={setUser} createUser={createUser} picks={picks} updateUser={updateUser}/>}/>
                            <Route path='/players' element={<Players players={players} results={results}/>}/>
                            <Route path='/results' element={<Results results={results} scrollIntoView={scrollIntoView}/>}/>
                            <Route path='/about' element={<About/>}/>
                        </Routes>
                    </div>
                </main>
            </div>
        </Router>
    );
}

export default App;
