import axios from "axios";

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const amounts = {
    'First Four&#174;': 0,
    'First Round': 5,
    'Second Round': 10,
    'Sweet 16&#174;': 20,
    'Elite Eight&#174;': 40,
    'FINAL FOUR&#174;': 75,
    'Championship': 150
};
const roundDefinitions = {
    'FIRST FOUR&#174;': 'Play-in',
    'FIRST ROUND': 'Opening Round',
    'SECOND ROUND': 'First Round',
    'SWEET 16#174;': 'Sweet 16',
    'ELITE 8#174;': 'Elite 8',
    'FINAL FOUR': 'Final 4',
    'CHAMPIONSHIP': 'Championship'
};
export const getScores = async (picks, loserScores, winnerScores) => {
    const requestConfig = {
        params: { year: 2024 },
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const response = await axios.get(
        // 'https://scoreboard.sock-monkey.co/get_scores.php',
        'https://us-central1-mm-scoreboard-154316.cloudfunctions.net/get-scores-v1',
        requestConfig
    )
    const games = response.data;
    const gameDisplayList = [];
    const rounds = {};

    for (const game in games) {
        const theGame = games[game].game;
        const bracketRound = theGame.bracketRound;
        if (theGame.home.names.short && theGame.away.names.short && bracketRound) {
            const amount = amounts[bracketRound];
            const gameDisplay = {};
            gameDisplay['id'] = theGame.gameID;
            gameDisplay['homeTeam'] = theGame.home.names.short;
            gameDisplay['homeScore'] = theGame.home.score;
            const homeTop = theGame.home.isTop === 'T';
            gameDisplay['awayRank'] = theGame.away.seed;
            gameDisplay['homeRank'] = theGame.home.seed;
            gameDisplay['homeIcon'] = theGame.home.names.seo;
            gameDisplay['awayTeam'] = theGame.away.names.short;
            gameDisplay['awayScore'] = theGame.away.score;
            gameDisplay['awayIcon'] = theGame.away.names.seo;
            gameDisplay['gameState'] = theGame.gameState;
            gameDisplay['finalMessage'] = theGame.finalMessage;
            let winner = 'nobody';
            if (gameDisplay['finalMessage'].includes('FINAL')) {
                const homeWinner = (parseInt(gameDisplay['awayScore']) < parseInt(gameDisplay['homeScore']));
                const winnerScore = homeWinner ? theGame.home.score.substr(-1) : theGame.away.score.substr(-1);
                const loserScore = !homeWinner ? theGame.home.score.substr(-1) : theGame.away.score.substr(-1);
                const row = loserScores.indexOf(loserScore);
                const column = winnerScores.indexOf(winnerScore);
                const winner = picks.find(pick => {
                    return pick.row === row && pick.column === column
                })
                const pickId = loserScores.indexOf(loserScore) + '' + winnerScores.indexOf(winnerScore);
                gameDisplay['winnerScore'] = winnerScore;
                gameDisplay['loserScore'] = loserScore;
                gameDisplay['winner'] = winner;
            }
            gameDisplay['player'] = winner;
            gameDisplay['amount'] = amount || 0;
            // playerWinnings[winner] += amount;

            const dateArr = theGame.startDate.trim().split('-');
            const month = dateArr[0];
            const day = dateArr[1];
            const startTime = !!theGame.startTime && theGame.startTime !== 'TBA' ? (theGame.startTime) : '12:00:00';
            const hour = parseInt(startTime.trim().substring(0, 2)) + 12;
            const minute = parseInt(startTime.trim().substring(3, 5));

            let options = {
                weekday: 'short', // long, short, narrow
                day: 'numeric', // numeric, 2-digit
                year: 'numeric', // numeric, 2-digit
                month: 'short', // numeric, 2-digit, long, short, narrow
            }
            if (theGame.startTime !== 'TBA') {
                options['hour'] = 'numeric' // numeric, 2-digit
                options['minute'] = 'numeric' // numeric, 2-digit
            }
            const theDate = new Date(2024, month - 1, day, hour - 1, minute, 0, 0).toLocaleString('en-US', options);
            gameDisplay['startDate'] = theDate;
            gameDisplay['startTime'] = theGame.startTime;
            gameDisplay['timeClock'] = theGame.gameState === 'live' ? theGame.contestClock : '';
            gameDisplay['network'] = theGame.network;
            gameDisplay['watchLiveUrl'] = theGame.watchLiveUrl;
            gameDisplay['currentPeriod'] = theGame.currentPeriod;
            if (rounds[(bracketRound)]) {
                rounds[(bracketRound)].push(gameDisplay);
            } else {
                rounds[(bracketRound)] = [gameDisplay];
            }
            gameDisplayList.push(gameDisplay);
        }
    }

    return {
        rounds,
        gameDisplayList,
        roundDefinitions,
    }

}
export default getScores;