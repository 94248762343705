import React from 'react';
import GameWinnerStatus from "./GameWinnerStatus";

const getLogo = icon => {
    return `//i.turner.ncaa.com/sites/default/files/images/logos/schools/bgl/${icon}.svg`
}

const Game = ({ game }) => {
    return (
        <div className="col-xl-6 col-sm-6 mb-4">
            <div className={`${game.gameState} card`}>
                <div className="card-header p-3 pt-2">
                    <div className="row text-center">
                        <div className="col-3 text-center">
                            <img src={getLogo(game.homeIcon)} className="team-logo"/>
                        </div>
                        <div className="col-3">
                            <div className="pt-1">
                                <h4 className="mb-0 badge fs-5 bg-gradient score">{game.homeScore || 0}</h4>
                            </div>
                        </div>
                        <div className="col-3">
                            <img src={getLogo(game.awayIcon)} className="team-logo"/>
                        </div>
                        <div className="col-3">
                            <div className="pt-1">
                                <h4 className="mb-0 badge fs-5 bg-gradient score">{game.awayScore || 0}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-6">
                        <span className="rank badge bg-dark rounded-pill">{game.homeRank}</span>
                        <span className="mb-1 mt-3 text-capitalize team">{game.homeTeam}</span>
                    </div>
                    <div className="col-6">
                        <span className="rank badge bg-dark rounded-pill">{game.awayRank}</span>
                        <span className="mb-1 mt-3 text-capitalize team">{game.awayTeam}</span>
                    </div>
                </div>
                <hr className="horizontal mb-1 light"/>
                <div className="card-footer p-3">
                    <div className="mb-0 text-center text-sm">
                        {game.startDate}
                        {(game.finalMessage || game.gameState === 'live') && (
                            <span className="ml-1 badge bg-gradient">{game.timeClock} {game.finalMessage}</span>
                        )}
                        <br/>

                        <GameWinnerStatus game={game}/>
                        {game.network && (
                            <span><i className="material-icons opacity-10 live-tv">live_tv</i> {game.network}</span>
                        )}

                    </div>
                </div>
            </div>
        </div>

    )
}
export default Game;
