import React, { useEffect } from 'react';
import Game from "../Game";
import LoadingProgressBar from "../LoadingProgressBar";

const Results = ({ results, scrollIntoView }) => {
    useEffect(() => {
        scrollIntoView()
    }, [])
    if (!results) {
        return (<LoadingProgressBar/>)
    }
    return (
        <div className="container-fluid">
            {Object.entries(results.rounds).map(round => (
                <div key={round[0]}>
                    <div className="row">
                        <div className="col-12">
                            <h2><i className="material-icons opacity-10 fs-3">ballot</i>
                                {round[0].replaceAll("&#174;", "")}</h2>
                        </div>
                    </div>
                    <div className="row">
                        {round[1].map(game => (
                            <Game game={game} round={round[0]} key={game.id}/>
                        ))}
                    </div>
                    <hr/>
                </div>
            ))}
        </div>
    );
}

export default Results;
