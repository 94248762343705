import React from 'react';

function About () {
    return (
        <div className="home">
            <h1>About</h1>
            <p>The rules are pretty simple. Kind of like a football board where the last number of the winner's score and the last number of the loser's score will match up to a grid with squares that people can buy.</p>

            <p>Each game in the tournament can win money but the further along the money grows. So, once the 100 squares are sold (at $10 each) we will put numbers across the top (0-9) for the winning score and numbers along the left side (0-9) for the loser's score.</p>

            <p>So, in the first round of the tournament when Kansas plays Northern Iowa and NI wins 82-67 the square that contains the person's name on the top for 2 and the left for 7 will win the money for that round which in this case is $5.</p>

            <h4>The purse for each round is as follows:</h4>

            <ul>
                <li>Play-in games $0</li>
                <li>1st round $5</li>
                <li>2nd round $10</li>
                <li>3rd round $20</li>
                <li>4th round $40</li>
                <li>5th round $75</li>
                <li>6th final game $150</li>
            </ul>
            <p>Once you've entered your picks either Venmo or Apple Pay.</p>

            <p>Apple Pay works great. Send to (612) 708-3858</p>

            <p>You can also send payment using Venmo @Matt-Christianson-5.</p>
        </div>
    );
}

export default About;
