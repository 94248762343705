import React, { useState } from 'react';

const GameWinnerStatus = ({ game }) => {
    if (game.winnerScore && game.loserScore) {
        return (
            <div className="">
                [{game.winnerScore}-{game.loserScore}] <span className="badge bg-success">{game.winner?.user?.name}</span> wins <span className="badge bg-success">${game.amount || 0}</span>
            </div>
        )
    }
    return (
        <div>Somebody will win ${game.amount}</div>
    )
}
export default GameWinnerStatus;
